/* MAIN FILTERBAR STYLE */
.filterbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 280px;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 10rem;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
  z-index: 3;
  background-color: var(--wit);
}

.filterbar-overlay {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  opacity: 0.7;
  background-color: white;
}

.filterbar-overlay--hidden {
  display: none;
  opacity: 0;
}

.filterbar__line {
  height: 100%;
  width: 2px;
  background-color: var(--grijs_light);
}

.filterbar__content {
  width: 100%;
  height: 100%;
  padding-right: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgb(175, 175, 175) var(--wit);

}

.filterbar__content__excess {
  flex-grow: 1;
}

.filterbar__content__excess:hover {
  cursor: pointer;
}

.filterbar__content__section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0.5rem 2rem;
}

.filterbar__content__section__header__title {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--geel);
}

.filterbar__content__section__header__clearbutton {
  color: var(--rood);
  font-size: 1rem;
  font-weight: 500;
}

/* SEARCH FIELD */
.filterbar__content__section__search {
  padding-left: 2rem;
}

.filterbar__content__section__search__searchbutton {
  width: 30%;
  font-weight: 600;
  height: 2rem;
  display: inline;
  background-color: var(--groen_medium);
  color: var(--wit);
  overflow: hidden;
}

/* RADIO BUTTONS */
.filterbar__radio__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  padding-left: 2rem;
  margin-bottom: 3rem;
}

.filterbar__radio__list__item {
  font-size: 0.95rem;
  font-weight: 500;
  background-color: var(--grijs_light);
  color: var(--blauw);
  text-align: center;
  padding: 0.25rem 0.5rem;
}

.filterbar__radio__list__item:hover {
  cursor: pointer;
}

.filterbar__radio__list__item--selected {
  background-color: var(--groen_medium);
  color: var(--wit);
}

/* SELECT BUTTONS */
.filterbar__select {
  position: relative;
  padding: 1rem 0;
  border-top: 1px solid var(--grijs_light);
  padding-left: 2rem;
}

.filterbar__select__cta {
  display: flex;
  justify-content: space-between;
}

.filterbar__select__cta__title {
  font-size: 1.2rem;
  font-weight: 500;
}

.filterbar__select__cta__button {
  transition: all 250ms ease-in-out;
}

.filterbar__select__cta__button--open {
  transform: rotate(45deg);
}

/* SELECTED FILTER */
.filterbar__select__list {
  max-height: 30vh;
  position: absolute;
  font-size: 1rem;
  background-color: var(--grijs_light);
  width: 248px;
  z-index: 50;
  left: 1rem;
  top: 3rem;
  padding: 0.5rem;
  padding-left: 2rem;
  overflow-y: auto;
}

.filterbar__select__list__item {
  margin: 0.5rem 0;
  font-weight: 600;
}

.filterbar__select__list__item:hover {
  color: var(--groen);
  cursor: pointer;
}

.filterbar__select__list__item--selected,
.filterbar__select__selected {
  color: var(--groen_medium);
  font-weight: 500;
}

.filterbar__select__selected {
  margin-right: 8px;
  text-transform: lowercase;
}

.filterbar__select__selected:hover {
  color: var(--rood);
  cursor: pointer;
}
